import { Component, Output, EventEmitter, Input } from '@angular/core';
import { ScreenShotAnimation } from './animation';
import { SHOWANIMATIONNODRAWING, SHOWANIMATIONDRAWING } from './const';

@Component({
	selector: 'eup-screen-shot',
	templateUrl: './screen-shot.component.html',
	styleUrls: ['./screen-shot.component.scss'],
	animations: ScreenShotAnimation,
})
export class ScreenShotComponent {

	@Input() showFlashAnimation: boolean;
	@Input() screenshotSrc: string;
	@Input() isDrawingToolAllowed: boolean;

	@Output() openDrawingTool = new EventEmitter<boolean>();

	constructor() {}

	onOpenDrawingTool() {
		this.openDrawingTool.next(true);
	}

	get animationState() {
		return this.showFlashAnimation && this.isDrawingToolAllowed ? SHOWANIMATIONDRAWING : SHOWANIMATIONNODRAWING;
	}
}
