<eup-drawing-tool
    *ngIf="showDrawingTool"
    (imageLoaded) = "onImageLoaded($event)"
    [capturedImageData] ="capturedImageData"
    [orderId]="orderId">        
</eup-drawing-tool>
<eup-screen-shot 
    *ngIf="showFlashAnimation"
    [isDrawingToolAllowed]="isDrawingToolAllowed"
    [screenshotSrc]="screenshotSrc" 
    [showFlashAnimation]="showFlashAnimation" 
    (openDrawingTool)="onOpenDrawingTool()"> 
</eup-screen-shot>