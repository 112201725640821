import { Injectable } from '@angular/core';
import { GlobalSettingsService } from '@core/globalSettings.service';
import { SoftwareOptionsService } from '@core/softwareOptions.service';
import { Consts } from '@shared/consts';
import { SoftwareOptionsForCompany } from '@shared/enums';
import { ChangeTitlePayload } from '@shared/generalInterfaces';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class StickyHeaderService {
	titleChanged$!: BehaviorSubject<ChangeTitlePayload>;
	screenshotsEnabled$!: BehaviorSubject<boolean>;
	downloadScreenshotsEnabled$!: BehaviorSubject<boolean>;
	screenshotClicked$!: BehaviorSubject<boolean>;
	screenshotsResult$!: BehaviorSubject<any>;

	get showImageHub() {
		const softwareOptions = this.globalSettingsService.get()?.companySoftwareOptions;
		const result = this.softwareOptionsService.areSoftwareOptionExist([SoftwareOptionsForCompany.ImageGallery], softwareOptions);

		return result;
	}

	get showAnnotationTool() {
		const softwareOptions = this.globalSettingsService.get()?.companySoftwareOptions;
		const result = this.softwareOptionsService.areSoftwareOptionExist([SoftwareOptionsForCompany.NewAnnotationTool], softwareOptions);

		return result;
	}

	constructor(private globalSettingsService: GlobalSettingsService, private softwareOptionsService: SoftwareOptionsService) {
		this.initSubjects();
	}

	initSubjects() {
		this.titleChanged$ = new BehaviorSubject<ChangeTitlePayload>({ title: '', logo: '', logoFallback: '' });
		this.screenshotsEnabled$ = new BehaviorSubject<boolean>(false);
		this.downloadScreenshotsEnabled$ = new BehaviorSubject<boolean>(false);
		this.screenshotClicked$ = new BehaviorSubject<boolean>(false);
		this.screenshotsResult$ = new BehaviorSubject<any>(null);    

	}

	triggerScreenshot() {
		this.screenshotClicked$.next(true);
	}

	resetLogo() {
		sessionStorage.setItem(Consts.Storage.Logo, JSON.stringify(''));
	}

	resetSubjects() {
		this.titleChanged$.next({ title: '', logo: '', logoFallback: '' });
		this.screenshotsEnabled$.next(false);
		this.downloadScreenshotsEnabled$.next(false);
		this.screenshotClicked$.next(false);
		this.screenshotsResult$.next(false);
	}

	callNext(propertyName: string, value: boolean) {
		if (this.hasOwnProperty(propertyName) && this[propertyName] instanceof BehaviorSubject) {
			(this[propertyName] as BehaviorSubject<boolean>).next(value);
		}
	}
}
